<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">
      <div class="flex">
        <div class="flex items-center mr-4" style="flex:2;">
          <span class="mr-2">优惠券</span>
          <div style="flex:1;">
            <a-select show-search style="width:100%;" allowClear
                      placeholder="搜索优惠券"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="suggestCouponList"
                      @change="onCouponSelect"
                      v-model="searchForm.coupon_id">
              <a-select-option v-for="item in couponList"
                               :key="item.id"
                               :value="item.id">{{item.name}}<span style="margin-left:10px;color:#ccc;">{{item.reduce_cost}}元</span></a-select-option>
            </a-select>
          </div>
        </div>
        <div class="flex items-center mr-4" style="flex:2;">
          <span class="mr-2">手机号</span>
          <div style="flex:1;">
            <a-input placeholder="用户手机号" v-model="searchForm.user_phone"></a-input>
          </div>
        </div>
        <div class="flex items-center mr-4" style="flex:3;">
          <span class="mr-2">兑换日期</span>
          <div style="flex:1;">
            <a-range-picker style="width:100%;" valueFormat="YYYY-MM-DD"
              v-model="other_order_pay_time" />
          </div>
        </div>
        <div class="flex items-center mr-4" style="flex:3;">
          <span class="mr-2">使用日期</span>
          <div style="flex:1;">
            <a-range-picker style="width:100%;" valueFormat="YYYY-MM-DD"
              v-model="use_time" />
          </div>
        </div>
        <div class="ml-8">
          <!-- <a-button class="mr-4 p-0" type="link" @click="handlerExport">导出</a-button> -->
          <a-button class="mr-4" @click="handlerResetForm">重置</a-button>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </div>
      </div>
      </a-form-model>
    </div>
    <div class="mt-2 mb-2">
      <span class="text-lg font-bold">中国移动积分商城兑换券</span>
      <a-button type="link" @click="isShowStatistics=true">查询售卖数据</a-button>
    </div>

    <base-table
      id="id"
      rowKey="id"
      :columnsData="yidongColumns"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #order_no="{record}">
        <a-button v-if="record.order_mode==1" class="p-0" type="link" @click="handlerOrderDetail(record.order_no)">{{record.order_no}}</a-button>
        <template v-else>{{record.order_no}}</template>
      </template>
      <template #use_time="{record}">
        <template v-if="record.use_time">
          {{record.use_time}}
          <a-tag v-if="record.push_use_status==1" class="ml-1 mr-0" color="orange">待同步</a-tag>
        </template>
        <template v-else-if="record.push_use_status==2">
          未使用
          <a-tag class="ml-1 mr-0" color="orange">待同步</a-tag>
        </template>
      </template>
      <template #user_id="{text}">
        <a-button class="p-0" type="link" @click="handlerUserDetail(text)">查看</a-button>
      </template>
      <template #provide_status="{record}">
        {{ formatCooStatus(record.provide_status) }}
        <a-button class="ml-2 p-0 red" type="link"
          v-if="record.provide_status==2" 
          @click="handlerOrderReset(record)">重发</a-button>
      </template>
      <!-- <template #operation="{record}">
        <a-button class="p-0 red" type="link"
          v-if="record.provide_status==0" @click="handlerOrderReset(record)">重发</a-button>
      </template> -->
    </base-table>

    <!-- 移动订单统计 -->
    <a-modal
      v-model="isShowStatistics"
      width="500px"
      title="移动订单统计"
      :footer="null"
    >
      <a-form-model 
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 19 }">
        <a-form-model-item prop="coupon_id" label="优惠券" class="mb-2">
          <a-select show-search style="width:100%;" allowClear
                    placeholder="搜索优惠券"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="suggestCouponList"
                    @change="onCouponSelect"
                    v-model="statisticsForm.coupon_id">
            <a-select-option v-for="item in couponList"
                             :key="item.id"
                             :value="item.id">{{item.name}}<span style="margin-left:10px;color:#ccc;">{{item.reduce_cost}}元</span></a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="mb-2" prop="other_order_pay_time" label="移动下单日期">
          <a-range-picker style="width:100%" valueFormat="YYYY-MM-DD"
              v-model="statisticsForm.other_order_pay_time" />
        </a-form-model-item>
        <a-form-model-item prop="use_time" label="核销使用日期">
          <a-range-picker style="width:100" valueFormat="YYYY-MM-DD"
              v-model="statisticsForm.use_time" />
        </a-form-model-item>
        <div class="flex justify-center mb-4">
          <a-button type="primary" @click="handlerStatistics">查询</a-button>
        </div>
      </a-form-model>
      <template v-if="cooYidongData">
        <a-divider />
        <div class="coo-yd-data">
          <span>券数量：{{cooYidongData.sell_quantity}}张；</span>
          <span>券面额总计：￥{{cooYidongData.sell_amount}}；</span>
          <span>券内部金额总计：￥{{cooYidongData.sell_interior_amount}}；</span>
        </div>
      </template>
    </a-modal>

  </div>
</template>

<script>
import { formatCooStatus } from "./columns.js"
import { getList } from "@/api/activity/coupon.js"
import { 
  cooYidongOrderList, 
  cooYidongOrderReset,
  cooYidongStatistics
} from "@/api/finance/yidong.js"
export default {
  data() {
    return {
      formatCooStatus,
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },

      couponList: [],

      other_order_pay_time: [],
      use_time: [],

      searchForm: {
        admin_type: 2, // 写死 财务类发票
        coupon_id: undefined,
        user_phone: "",
        other_order_pay_time_start: "",
        other_order_pay_time_end: "",
        use_time_start: "",
        use_time_end: "",
        page: 1,
        page_count: 20,
      },
      tableData: [],
      total: 0,
      
      curRow: {},
      yidongColumns: [
        {
          title: "移动订单号",
          dataIndex: "other_order_no",
          align: "center",
        },
        {
          title: "用户手机号",
          dataIndex: "user_phone",
          align: "center",
          width: 120,
          slots: {
            customRender: 'user_phone'
          },
          slotsType: 'format',
          slotsFunc: (val) => {
            if(val){
              return String(val).substr(0,3)+"****"+String(val).substr(7,4)
            }
            return val
          }
        },
        {
          title: "优惠券",
          dataIndex: "coupon_name",
          align: "center",
        },
        {
          title: "兑换时间",
          dataIndex: "other_order_pay_time",
          align: "center",
        },
        {
          title: "使用时间",
          dataIndex: "use_time",
          align: "center",
          width: 260,
          slots: {
            customRender: 'use_time'
          },
        },
        {
          title: '发放状态',
          dataIndex: 'provide_status',
          align: 'center',
          width: 130,
          slots: {
            customRender: 'provide_status'
          },
          // slotsType: 'format',
          // slotsFunc: (val) => formatCooStatus(val)
        },
        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   width: 80,
        //   align: "center",
        //   slots: {
        //     customRender: "operation",
        //   },
        // },
      ],

      statisticsForm: {
        coupon_id: undefined,
        other_order_pay_time_start: "",
        other_order_pay_time_end: "",
        other_order_pay_time: [],
        use_time_start: "",
        use_time_end: "",
        use_time: [],
      },
      isShowStatistics: false,
      cooYidongData: null
    }
  },
  created(){

  },
  mounted() {
    this.handlerSearch()
  },
  methods: {
    async initData() {
      this.searchForm.other_order_pay_time_start = ""
      this.searchForm.other_order_pay_time_end   = ""
      this.searchForm.use_time_start = ""
      this.searchForm.use_time_end   = ""

      if (this.other_order_pay_time.length == 2) {
        this.searchForm.other_order_pay_time_start = this.other_order_pay_time[0] + " 00:00:00"
        this.searchForm.other_order_pay_time_end   = this.other_order_pay_time[1] + " 23:59:59"
      }
      if (this.use_time.length == 2) {
        this.searchForm.use_time_start = this.use_time[0] + " 00:00:00"
        this.searchForm.use_time_end   = this.use_time[1] + " 23:59:59"
      }
      const params = this.searchForm

      const { data, code } = await cooYidongOrderList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    
    // 搜索
    handlerSearch() {
      if(this.searchForm.exchange_code){
        this.searchForm.coupon_id = undefined
        this.other_order_pay_time = []
        this.use_time = []
      }

      this.searchForm.page = 1
      this.initData()
    },
    // 导出
    handlerExport(){
      const params = {
        admin_type: this.searchForm.admin_type,
        other_order_pay_time_start: "",
        other_order_pay_time_end:   "",
        use_time_start: "",
        use_time_end:   "",
      }

      if(this.other_order_pay_time.length < 2 && this.use_time.length < 2){
        this.$message.info("兑换时间、使用时间不能都为空")
        return
      }

      if (this.other_order_pay_time.length == 2) {
        if(new Date(this.other_order_pay_time[1]).getTime() - new Date(this.other_order_pay_time[0]).getTime() > 31 * 24 * 3600 * 1000){
          this.$message.warning("兑换时间跨度不能超一个月")
          return
        }
        params.other_order_pay_time_start = this.other_order_pay_time[0]
        params.other_order_pay_time_end   = this.other_order_pay_time[1]
      }
      if (this.use_time.length == 2) {
        if(new Date(this.use_time[1]).getTime() - new Date(this.use_time[0]).getTime() > 31 * 24 * 3600 * 1000){
          this.$message.warning("使用时间跨度不能超一个月")
          return
        }
        params.use_time_start = this.use_time[0]
        params.use_time_end   = this.use_time[1]
      }

      // exportRecord(params)
    },
    // 重置
    handlerResetForm() {
      this.searchForm.coupon_id = undefined

      this.other_order_pay_time = []
      this.use_time = []
      this.couponList = []

      this.handlerSearch()
    },

    async suggestCouponList (value) {
      const params = {
        admin_type: this.searchForm.admin_type,
        name: value,
        date_type: 0,
        page_count: 10,
      }
      if (value) {
        const { data, code } = await getList(params)
        if (code === 0) {
          this.couponList = data.list
        }
      }else{
        this.couponList = []
      }
    },

    onCouponSelect(){
      this.handlerSearch()
    },

    handlerUserDetail(user_id){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { member_id: user_id },
      });
      window.open(newPage.href, "_blank");
    },

    handlerOrderDetail(order_no) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: order_no },
      });
      window.open(newPage.href, "_blank");
    },

    async handlerOrderReset(record){
      const { code } = await cooYidongOrderReset({
        id: record.id
      })
      if(code == 0){
        this.$message.success("操作成功")
        this.initData()
      }
    },

    async handlerStatistics(){
      // if(!this.statisticsForm.coupon_id){
      //   this.$message.info("请选择优惠券")
      //   return
      // }
      const params = { 
        coupon_id: this.statisticsForm.coupon_id || ""
      }
      if(this.statisticsForm.other_order_pay_time.length == 2){
        Object.assign(params, {
          other_order_pay_time_start: this.statisticsForm.other_order_pay_time[0] + " 00:00:00",
          other_order_pay_time_end:   this.statisticsForm.other_order_pay_time[1] + " 23:59:59",
        })
      }
      if(this.statisticsForm.use_time.length == 2){
        Object.assign(params, {
          use_time_start: this.statisticsForm.use_time[0] + " 00:00:00",
          use_time_end:   this.statisticsForm.use_time[1] + " 23:59:59",
        })
      }
      if(!params.coupon_id && !params.other_order_pay_time_start && !params.use_time_start){
        this.$message.info("请填写至少一个查询内容")
        return
      }
      
      const { code, data } = await cooYidongStatistics(params)
      if(code == 0){
        this.cooYidongData = data
      }
    },

  },
}
</script>

<style lang="less">
.coo-yd-data{
  padding-bottom: 20px;
  span:not(:last-child){
    margin-right: 20px;
  }
}
</style>