import ajax from '@/utils/ajax.js'


// ---------------- 中国移动
/**
 * 中国移动-订单列表
 * @param {*} params 
 * @returns
 */
export function cooYidongOrderList(params) {
  return ajax.post('/YdOrder/getList',params)
}

/**
 * 中国移动-订单重置/触发重新发放
 * @param {*} params 
 * @returns
 */
export function cooYidongOrderReset(params) {
  return ajax.post('/YdOrder/resetProvide',params)
}

/**
 * 中国移动-订单报表
 * @param {*} params 
 * @returns
 */
export function cooYidongStatistics(params) {
  return ajax.post('/YdOrder/statistics',params)
}
